import React from 'react';

export function Footer() {

  return (<>
    <div className="text-secondary px-4 text-center">
      <div className="py-0">
        <span className='made-with-love'>DApp made with <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg> by <a href="https://profile.rpgmax.fr" target="_blank" rel="noreferrer">RpGmAx</a></span>
      </div>
    </div>
    </>)
}
